import Vue from "vue";
import api from "@axios";
import store from "@/store";
import { convertHTMLEntity } from "@/global/functions.js";

// IMEI Check Service
class ImeiCheck {
    // Single check
    static async single(formData, servicePrice = 0) {
        try {
            api.defaults.headers.post["version"] = "v3.7.3"; // Set API version
            const { data } = await api.post("/imei/single", formData);
            
            // Decrease Balance only if servicePrice is valid
            if (servicePrice > 0) {
                store.commit("HANDLE_CREDIT", -servicePrice);
            }

            return data;
        } catch (error) {
            console.error("Single check error:", error);
            await this.handleErrorPopup(error);
            throw error;
        }
    }

    // Bulk check
    static async bulk({ imei, service_id, mail, ...rest }) {
        try {
            const imeis = [...new Set(imei.split("\n").map(e => e.trim()))].filter(Boolean);

            api.defaults.headers.post["version"] = "v3.7.3"; // Set API version
            const { data } = await api.post("/imei/bulk", { imeis, service_id, mail, ...rest });

            return data;
        } catch (error) {
            console.error("Bulk check error:", error);
            await this.handleErrorPopup(error);
            throw error;
        }
    }

    // Handle error popups
    static async handleErrorPopup(error) {
        if (!error.response) {
            console.error("Unexpected error:", error);
            return;
        }

        const { response: resErr, popup, errors } = error.response.data || {};
        const status = error.response.status;

        let errorMessage = "Server error..";
        if (status === 403) {
            errorMessage = resErr || "Access denied.";
        } else if (status === 422 && errors) {
            errorMessage = Object.values(errors)?.[0]?.[0] || "Validation error.";
        }

        console.log("API Error Response:", error.response.data);

        if (popup || status === 422) {
            await Vue.prototype.$swal({
                title: "Error",
                html: convertHTMLEntity(errorMessage),
                icon: "error",
                confirmButtonText: "Ok, understand",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
            });
        }
    }
}

export default ImeiCheck;
