<template>
  <CardHeader>
    <template v-slot:topheader>
      <div>
        <div v-if="!loading">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :pressed="!bulk"
            variant="flat-primary"
            @click="bulk = false"
            pill
          >
            <feather-icon icon="SearchIcon" />
            Order
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :pressed="bulk"
            variant="flat-primary"
            @click="bulk = true"
            pill
          >
            <feather-icon icon="ListIcon" />
            Bulk
          </b-button>
        </div>
        <div class="loading-header" v-else>
          <b-spinner class="mr-2" variant="warning" label="loading.." />
          Loading..
        </div>
      </div>
    </template>
    <template>
      <div v-show="!loading">
        <div class="d-flex justify-content-end">
          <div class="whatsapp-note-btn">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="mr-2 btn btn-gradient-primary btn-lg rounded-pill"
              size="sm"
              @click="onClickWhatsappNote"
            >
              <img
                src="@/assets/images/icons/whatsapp.svg"
                height="20"
                style="margin-right: 5px;"
              />
              NOTE
            </b-button>
          </div>
          <div class="d-flex align-items-center flex-column">
            <b-form-checkbox
              id="saved-service-checkbox"
              v-b-tooltip.hover.bottom
              title="Enabling this button saves the last service used."
              @change="onToggleSaveService"
              v-model="save_service"
              name="check-button"
              switch
            />
            <b style="margin-top: 5px;">
              Save Service
            </b>
          </div>
        </div>
        <CheckStep
          :bulk="bulk"
          @handleBulk="(v) => (bulk = v)"
          @setService="onChangeService"
          @setImei="(s) => (service = s)"
          @submit="submit"
        />
        <!-- Display information -->
        <ServiceInfoStep
          v-if="service != null"
          :data="service"
          :duplicate="duplicate"
          :mail="mail"
          @setDuplicate="(v) => (duplicate = v)"
          @setMail="(v) => (mail = v)"
        />
      </div>
      <div v-show="loading" class="please-wait">
        <h2>Please Wait ..</h2>
        <br />
        <progress></progress>
      </div>
    </template>
    <WhatsappNoteDialog />
    <SendVerificationCodeDialog
      :id="sendVerificationModalId"
      @codeSent="onCodeSent"
    />
    <VerifyCodeDialog
      :id="verifyCodeModalId"
      :mobileNumber="wpMobileNumber"
      @changeNumber="onClickChangeNumber"
    />
  </CardHeader>
</template>

<script>
import CheckStep from "@/components/imei/CheckStep.vue";
import ServiceInfoStep from "@/components/imei/ServiceInfoStep.vue";
import Ripple from "vue-ripple-directive";
import { BButton, BFormCheckbox, VBTooltip } from "bootstrap-vue";
import dublicateModal from "@/components/imei/duplicateModal.js";
import CardHeader from "@/components/UI/CardHeader.vue";
import ImeiCheck from "@/services/imei.service.js";
import WhatsappNoteDialog from "@/components/whatsapp/WhatsappNoteDialog.vue";
import SendVerificationCodeDialog from "@/components/whatsapp/SendVerificationCodeDialog.vue";
import VerifyCodeDialog from "@/components/whatsapp/VerifyCodeDialog.vue";
import { mapState } from "vuex";
import auth from "@/services/auth.service.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data: () => ({
    loading: false,
    wpLoading: false,
    service: null,
    bulk: false,
    // duplicate: false,
    duplicate: JSON.parse(localStorage.getItem("duplicate")) ?? true,
    //  mail: false,
    mail: JSON.parse(localStorage.getItem("mail")) ?? false,
    save_service: false,
    wpMobileNumber: "",
    sendVerificationModalId: "modal-send-to-whatsapp-check",
    verifyCodeModalId: "modal-verification-code-check",
  }),
  computed: {
    ...mapState({ userInfo: "user" }),
  },
  watch: {
    duplicate(newDuplicate) {
      localStorage.duplicate = newDuplicate;
    },
    mail(newMail) {
      localStorage.mail = newMail;
    },
  },

  methods: {
    onClickChangeNumber() {
      this.showSendVerificationDialog = true;
      this.$bvModal.show(this.sendVerificationModalId);
    },
    onCodeSent(value) {
      this.wpMobileNumber = value.mobileNumber;
      this.$bvModal.show(this.verifyCodeModalId);
    },
    async onClickWhatsappNote() {
      this.$bvModal.show("modal-whatsapp-note");
    },
    onToggleSaveService(value) {
      if (value && this.service) {
        localStorage.setItem("saved_service", this.service.id);
      } else {
        localStorage.removeItem("saved_service");
      }
    },
    onChangeService(s) {
      this.service = s;
      if (this.save_service) {
        localStorage.setItem("saved_service", this.service.id);
      }
    },
    async submit(imei) {
      const { service, bulk, duplicate, mail } = this;
      const formData = {
        imei,
        service_id: service.id,
        duplicate,
        mail,
      };
      const navigate = (response) => {
        this.$router.push({
          name: bulk ? "imei-process-multi" : "imei-process-single",
          params: {
            imei,
            service,
            ...response,
          },
        });
      };
      this.loading = true;
      try {
        const dataRes = !bulk
          ? await ImeiCheck.single(formData, service.price)
          : await ImeiCheck.bulk(formData);
        navigate(!bulk ? dataRes : { orders: dataRes });
      } catch (e) {
        const { response, status, time_diff } = e.response?.data;
        status == "duplicate"
          ? dublicateModal(
              this,
              time_diff,
              formData,
              navigate,
              response,
              service.price
            )
          : ImeiCheck.openPopup(e);
        this.loading = false;
      }
    },
    notify({ variant = "success", text = "" } = {}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    BFormCheckbox,
    CheckStep,
    ServiceInfoStep,
    BButton,
    CardHeader,
    WhatsappNoteDialog,
    SendVerificationCodeDialog,
    VerifyCodeDialog,
  },
  mounted() {
    if (localStorage.getItem("saved_service")) {
      this.save_service = true;
    }
  },
};
</script>

<style lang="scss" scopped>
@media screen and (max-width: 600px) {
  .whatsapp-note-btn {
    display: none;
  }
}
.please-wait {
  text-align: center;
  padding: 95px 0;
}

.loading-header {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 22px;
}
</style>
